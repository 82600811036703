<template>
  <div class='bar-show-lang'>
    <!-- <p class='bar-show-lang__left'>{{ title }}</p> -->
    <div class='bar-show-lang__left'>
      <i class='fas fa-code' />
    </div>
    <ul class='bar-show-lang__right'>
      <li v-for='langName in setLangList' :key='langName.id'>
        {{ langName }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    lang: String,
  },
  computed: {
    setLangList() {
      return this.lang.split(',');
    },
  },
};
</script>

<style lang="scss">
.bar-show-lang {
  display: flex;
}

.bar-show-lang__left {
  background-color: $color-dark-gray;
  padding: 10px;
  font-size: 12px;
  color: $color-white;
  display: flex;
  align-items: center;
}

.bar-show-lang__right {
  background-color: $color-gray;
  padding: 10px;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  max-width: 300px;
  li {
    margin-right: 5px;
  }
}
</style>
