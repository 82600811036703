<template>
  <div class='dashboard'>
    <layout-header-sp />
    <main class='dashboard__main'>
      <section>
        <div class='dashboard__main__notice'>
          <div class='dashboard__main__notice__left' v-emoji>
            📱
          </div>
          <div class='dashboard__main__notice__right'>
            <h3>
              スマホモードで表示しています
            </h3>
            <p>
              PCからのご利用を推奨しております。コースの変更やビデオチャットでのご質問などはPCから可能です。
            </p>
          </div>
        </div>
        <a class='dashboard__main__space-link' href='https://discord.gg/TqB9DBCCNg' target='_blank'>
          <img :src='imageUrlDonblerSpace'>
        </a>
        <section-course-sp
          :courseInfo='$store.state.study.courseInfo'
          :progress='calcProgress'
          v-if='judgeShowCourseInfo'
        />
        <section-lesson-sp
          :lessonInfo='JSON.parse(JSON.stringify(this.$store.state.study.lessonInfo))'
          :progressInfo='$store.state.study.progressInfo'
          v-if='judgeShowLessonInfo'
        />
      </section>
    </main>
    <layout-footer-sp />
  </div>
</template>

<script>
import LayoutHeaderSp from '@/components/sps/LayoutHeaderSp.vue';
import LayoutFooterSp from '@/components/sps/LayoutFooterSp.vue';
import SectionCourseSp from '@/components/sps/SectionCourseSp.vue';
import SectionLessonSp from '@/components/sps/SectionLessonSp.vue';
import axios from 'axios';

export default {
  components: {
    SectionCourseSp,
    SectionLessonSp,
    LayoutHeaderSp,
    LayoutFooterSp,
  },
  data() {
    return {
      imageUrlDonblerSpace: `${process.env.VUE_APP_S3_MATERIAL_URL}/images/other/toppage/webp/donbler_space_banner_sp.webp`,
    };
  },
  async created() {
    await this.execLoadCourseInfo();
    const result = await this.$store.dispatch('loadLessonInfo');
    if (!result) await this.$router.push({ name: 'Error' });
    if (this.$store.state.user.signupTutorial) this.sendSignUpAnalysis();
  },
  methods: {
    async execLoadCourseInfo() {
      const result = await this.$store.dispatch('loadCourseInfo');
      if (result === 401) {
        this.$router.push({ name: 'Top' });
      } else if (!result) {
        this.$router.push({ name: 'Error' });
      }
    },
    async execLoadTicketInfo() {
      const result = await this.$store.dispatch('loadTicketInfo');
      if (result === 401) {
        this.$router.push({ name: 'Top' });
      } else if (!result) {
        this.$router.push({ name: 'Error' });
      }
    },
    async sendSignUpAnalysis() {
      const params = this.$store.state.analysis.signupParams;
      if (params.utm_source !== null) {
        const headers = {
          Authorization: `Bearer ${this.$store.state.user.token}`,
        };
        await axios.post(
          `${process.env.VUE_APP_HOST}/api/auth/analysis/signup`,
          params,
          { headers },
        );
      }
    },
  },
  computed: {
    judgeShowCourseInfo() {
      return this.$store.state.study.courseInfo.id !== '';
    },
    judgeShowLessonInfo() {
      return this.$store.state.study.lessonInfo.length !== 0;
    },
    lessonLimit() {
      return Number(process.env.VUE_APP_FREE_COURSE_LESSON_LIMIT);
    },
    chapterLimit() {
      return Number(process.env.VUE_APP_FREE_COURSE_CHAPTER_LIMIT);
    },
    calcProgress() {
      let completionCnt = 0;
      let lessonAllCnt = 0;
      let allLesson = JSON.parse(JSON.stringify(this.$store.state.study.lessonInfo));
      // 無料枠制御
      if (this.$store.state.study.courseInfo.id === 1 && allLesson.length !== 0) {
        allLesson = allLesson.slice(0, this.lessonLimit);
        const sliceChapterNameCnt = allLesson.slice(-1)[0].chapterName.slice(0, this.chapterLimit);
        allLesson.slice(-1)[0].chapterName = sliceChapterNameCnt;
      }

      allLesson.forEach((v) => {
        for (let i = 1; v.chapterName.length + 1 > i; i += 1) {
          const lesson = {
            categoryId: v.categoryId,
            lessonId: v.lessonId,
            // chapterIdは存在しないので新たに付与する
            chapterId: i,
          };

          if (this.$store.state.study.progressInfo
            .some((progress) => progress.categoryId === lesson.categoryId
            && progress.lessonId === lesson.lessonId
            && progress.chapterId === lesson.chapterId)) completionCnt += 1;

          lessonAllCnt += 1;
        }
      });
      const progress = Math.floor((completionCnt / lessonAllCnt) * 100);
      return Number.isNaN(progress) ? 0 : progress;
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  -webkit-text-size-adjust: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.dashboard__main {
  margin: 0 auto 120px;
  padding: 0 15px;
  display: flex;
  max-width: 100%;
}

.dashboard__main__notice {
  background-color: $color-white;
  padding: 15px;
  display: flex;
  margin-bottom: 15px;
}

.dashboard__main__notice__left {
  width: 50px;
  height: 50px;
  background-color: $color-gray;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.dashboard__main__notice__right {
  text-align: left;
  margin: 0 15px;
  h3 {
    font-size: 14px;
    @extend .strimwidth-one;
  }
  p {
    font-size: 10px;
    @extend .strimwidth-two;
  }
}

.dashboard__main__space-link {
  width: 100%;
  img {
    width: 100%;
    margin-bottom: 10px;
  }
}

</style>

<style lang="scss">
.dashboard__main__notice__left {
  img {
    height: 22px;
    margin: 10px 0;
    vertical-align: middle;
  }
}
</style>
