<template>
  <section class='section-lesson-sp'>
    <ul class='section-lesson-sp__map'>
      <li
        class='section-lesson-sp__map__list'
        v-for='(lesson, index) in filterLessonInfo' :key='lesson.id'
      >
        <div class='section-lesson-sp__map__list__left' />
        <div
          class='section-lesson-sp__map__list__center'
          v-if='index < filterLessonInfo.length -1'
        />
        <div class='section-lesson-sp__map__list__right'>
          <h5>Lesson{{ index + 1 }}: {{ lesson.lessonName }}</h5>
          <p>{{ lesson.lessonDescription }}</p>
          <ul>
            <li v-for='(chapter, index) in lesson.chapterName' :key='index'>
              <button-dashboard-chapter-sp
                :check='judgeCheck(lesson.categoryId, lesson.lessonId,index + 1)'
                :categoryId='lesson.categoryId'
                :lessonId='lesson.lessonId'
                :chapterId='index + 1'
              >
                  <span>{{ index+1 + '. ' + chapter }}</span>
              </button-dashboard-chapter-sp>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <div class='section-lesson-sp__recommend-paid' v-if='judgeShowRecommendPaid'>
      <div class='section-lesson-sp__recommend-paid__left' v-emoji>
        👋
      </div>
      <div class='section-lesson-sp__recommend-paid__right'>
        <h3>続きは有料コースで学習！</h3>
        <p>
          無料コースで受講できるのはここまでです。より実践的な内容を学べる有料コースにチャレンジしてみませんか？
          詳しい手順は
          <a href='https://note.com/donbler/n/nbcd97fd352aa' target="_blank">
            こちら
          </a>
          から。<br>
          <span>
            (コースの変更はPC版Donblerから可能です。)
          </span>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import ButtonDashboardChapterSp from '@/components/sps/ButtonDashboardChapterSp.vue';

export default {
  props: {
    lessonInfo: Array,
    progressInfo: Array,
  },
  components: {
    ButtonDashboardChapterSp,
  },
  computed: {
    judgeShowRecommendPaid() {
      return this.$store.state.study.courseInfo.id === 1;
    },
    lessonLimit() {
      return Number(process.env.VUE_APP_FREE_COURSE_LESSON_LIMIT);
    },
    chapterLimit() {
      return Number(process.env.VUE_APP_FREE_COURSE_CHAPTER_LIMIT);
    },
    filterLessonInfo() {
      // 無料枠制御
      if (this.$store.state.study.courseInfo.id === 1) {
        const lesson = this.lessonInfo.slice(0, this.lessonLimit);
        const sliceChapterNameCnt = lesson.slice(-1)[0].chapterName.slice(0, this.chapterLimit);
        lesson.slice(-1)[0].chapterName = sliceChapterNameCnt;
        return lesson;
      }
      return this.lessonInfo;
    },
  },
  methods: {
    judgeCheck(categoryId, lessonId, chapterId) {
      const target = this.progressInfo.filter((v) => v.categoryId === categoryId
        && v.lessonId === lessonId
        && v.chapterId === chapterId);
      return target.length !== 0;
    },
  },
};
</script>

<style lang="scss">
.section-lesson-sp {
  background-color: $color-white;
  padding: 15px 15px 15px;
}

.section-lesson-sp__map {
  margin: 0;
}

.section-lesson-sp__map__list {
  display: flex;
  margin-bottom: 40px;
  position: relative;
}

.section-lesson-sp__map__list:last-child {
  margin-bottom: 0;
}

.section-lesson-sp__map__list__left {
  background-color: $color-white;
  border: 3px solid $color-yellow;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  margin: 2px 15px 0 0;
  flex-shrink: 0;
  z-index: 2;
}

.section-lesson-sp__map__list__center {
  border-right: 3px solid $color-yellow;
  height: calc(100% + 40px);
  top: 15px;
  left: 8px;
  position: absolute;
}

.section-lesson-sp__map__list__right {
  h5 {
    font-size: 16px;
    font-weight: 600;
    @extend .strimwidth-one;
  }
  p {
    font-size: 12px;
    margin: 5px 0 10px;
    @extend .strimwidth-three;
  }
  li {
    margin-top: 10px;
  }
  li:first-child {
    margin-top: 0;
  }
}

.section-lesson-sp__recommend-paid {
  display: flex;
  padding: 15px;
  margin-top: 30px;
  background-color: $color-light-gray;
  border-radius: 3px;
}

.section-lesson-sp__recommend-paid__left {
  margin-right: 10px;
  img {
    width: 35px;
  }
}

.section-lesson-sp__recommend-paid__right {
  h3 {
    font-size: 14px;
  }
  p {
    margin-top: 3px;
    font-size: 12px;
  }
  a {
    color: $color-blue;
    font-weight: 600;
    text-decoration: none;
  }
  a:visited {
    color: $color-blue;
  }
  span {
    font-size: 10px;
  }
}
</style>
