<template>
  <router-link
    class='button-dashboard-chapter-sp'
    :to='{
      name: "SpStudy",
      params: { categoryId: categoryId, lessonId: lessonId, chapterId }
    }'
  >
    <div class='button-dashboard-chapter-sp__check' :class='addClass'>
      <i class="fas fa-check" v-if='check' />
    </div>
    <div class='button-dashboard-chapter-sp__text'>
      <slot />
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    categoryId: Number,
    lessonId: Number,
    chapterId: Number,
    check: Boolean,
  },
  computed: {
    addClass() {
      return {
        check: this.check,
      };
    },
  },
};
</script>

<style lang="scss">
.button-dashboard-chapter-sp {
  color: $color-black;
  border: 1px solid $color-gray;
  border-radius: 2px;
  font-size: 10px;
  display: flex;
  padding: 0;
  text-decoration: none;
}

.button-dashboard-chapter-sp:visited {
  color: $color-black;
}

.button-dashboard-chapter-sp__text:hover {
  text-decoration: underline;
  text-underline-offset: 1px;
}

.button-dashboard-chapter-sp__check {
  width: 45px;
  height: 45px;
  background-color: $color-light-gray;
  border-right: 1px solid $color-gray;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-size: 16px;
    color: #fff;
  }
}

.button-dashboard-chapter-sp__text {
  padding: 5px;
  // max-width: 200px;
  text-align: left;
  span {
    @extend .strimwidth-two;
  }
}

.check {
  background-color: $color-blue;
}
</style>
